import { authApi } from '@/api-client/auth';
import { IProfile } from '@/interface/auth-v2';
import { LoginRoleKey } from '@/interface/enum';
import { notification } from 'antd';
import useSWR from 'swr';
import { PublicConfiguration } from 'swr/_internal';
import { CookieStoreControl } from './cookie-storage';
import { useRouter } from './useRouter';

const HOURSE_TO_MILISECOND = 3000;
export const instance = CookieStoreControl.getInstance();
export function useAuth(options?: Partial<PublicConfiguration>) {
  const {
    data: payload,
    error,
    mutate,
  } = useSWR('auth/profile', {
    dedupingInterval: HOURSE_TO_MILISECOND,
    revalidateOnFocus: true,
    ...options,
  });

  const router = useRouter();

  async function login(payload: { email: string; phone: string; password: string; role: string }) {
    try {
      const {
        data: { refresh_token, refresh_token_expired, access_token, access_token_expired },
      } = await authApi.login({
        email: payload?.email || null,
        phone: payload?.phone || null,
        password: payload.password,
        role: payload.role,
      });

      if (!refresh_token || !refresh_token_expired || !access_token || !access_token_expired) {
        return false;
      }

      instance.token.set_access_token(access_token, access_token_expired);
      instance.token.set_refresh_token(refresh_token, refresh_token_expired);

      await mutate();
      notification.success({ message: 'Login', description: 'Successfully' });
      if (payload.role === LoginRoleKey.CUSTOMER) {
        setTimeout(() => router.push('/'), 1000);
      } else {
        router.push('/admin/dashboard');
      }

      return true;
    } catch (error: any) {
      notification.error({ message: 'Password', description: `${error?.response?.data?.message}` });
    }
  }

  async function logout() {
    const rfToken = instance.token.get_refresh_token();

    if (rfToken) {
      await authApi.logout(rfToken);
      instance.token.remove_refresh_token();
      instance.token.remove_access_token();

      notification.success({ message: 'Logout', description: 'Successfully' });
      setTimeout(() => window.location.replace('/'), 1500);
      mutate({}, false);
    }
  }

  const firstLoading = payload === undefined && error === undefined;

  return {
    profile: payload?.data as IProfile,
    error,
    firstLoading,
    logout,
    login,
    mutateProfile: mutate,
  };
}
